//App.js
import React, { useState, useEffect, useRef } from 'react';
// import { useNavigate, useSearchParams } from 'react-router-dom';
import WebSocketProvider from './WebSocketProvider';
import MessageList from './MessageList';
import MessageInput from './MessageInput';
import SideDrawer from './SideDrawer'; // Import a side-drawer component for threads
import CountrySelect from './CountrySelect';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { faGoogle } from '@fortawesome/free-brands-svg-icons';
import './App.css';
import ConnectionIndicator from './ConnectionIndicator';
import { MlKem768 } from 'mlkem';
import { toByteArray, fromByteArray } from 'base64-js';

// Map of App names
const appNames = {
    "sinbi": "Sinbi",
    "pglux": "PGlux",
};

function base64ToUint8Array(base64String) {
    return toByteArray(base64String);
};
function uint8ArrayToBase64(uint8Array) {
    return fromByteArray(uint8Array);
};
const onewayHash = async (input) => {
    const encoder = new TextEncoder();
    const data = encoder.encode(input);
    const hashBuffer = await window.crypto.subtle.digest('SHA-512', data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
    return hashHex;
};
const deriveKey = async (inputSharedSecret, salt, length = 32) => {
    const sharedSecretBuffer = new TextEncoder().encode(inputSharedSecret);
    const saltBuffer = new TextEncoder().encode(salt);

    const keyMaterial = await window.crypto.subtle.importKey(
        'raw',
        sharedSecretBuffer,
        { name: 'HKDF' },
        false,
        ['deriveKey']
    );

    const derivedKey = await window.crypto.subtle.deriveKey(
        {
            name: 'HKDF',
            hash: 'SHA-256',
            salt: saltBuffer,
            info: new Uint8Array(),
        },
        keyMaterial,
        { name: 'AES-GCM', length: length * 8 },
        true,
        ['encrypt', 'decrypt']
    );

    const keyBuffer = await window.crypto.subtle.exportKey('raw', derivedKey);
    return new Uint8Array(keyBuffer);
};
const singleEncryptMessage = async (key, plainTextMessage) => {
    const iv = window.crypto.getRandomValues(new Uint8Array(12)); // Generate a 12-byte random IV
    const encoder = new TextEncoder();
    const plainTextBuffer = encoder.encode(plainTextMessage);

    const cryptoKey = await window.crypto.subtle.importKey(
        'raw',
        key,
        'AES-GCM',
        false,
        ['encrypt']
    );

    try {
        console.log("Attempting encryption...");
        const encryptedBuffer = await window.crypto.subtle.encrypt(
            {
                name: 'AES-GCM',
                iv: iv,
                tagLength: 128, // 128-bit tag length
            },
            cryptoKey,
            plainTextBuffer
        );

        // Extract ciphertext and tag from the encrypted buffer
        const ciphertextBuffer = encryptedBuffer.slice(0, encryptedBuffer.byteLength - 16); // Last 16 bytes are the tag
        const tagBuffer = encryptedBuffer.slice(encryptedBuffer.byteLength - 16);

        // Chunked Base64 encoding for ciphertext and tag
        const chunkedBase64Encode = (buffer) => {
            const bytes = new Uint8Array(buffer);
            const chunkSize = 8192; // Process in 8KB chunks
            let binaryString = '';

            for (let i = 0; i < bytes.length; i += chunkSize) {
                binaryString += String.fromCharCode(...bytes.subarray(i, i + chunkSize));
            }

            return btoa(binaryString);
        };

        return {
            ciphertext: chunkedBase64Encode(ciphertextBuffer),
            iv: chunkedBase64Encode(iv),
            tag: chunkedBase64Encode(tagBuffer),
        };
    } catch (error) {
        console.error('Error encrypting message:', error);
    }
};
const singleDecryptMessage = async (key, encryptedMessage) => {
    const { ciphertext, iv, tag } = encryptedMessage;

    const ciphertextBuffer = Uint8Array.from(atob(ciphertext), c => c.charCodeAt(0));
    const ivBuffer = Uint8Array.from(atob(iv), c => c.charCodeAt(0));
    const tagBuffer = Uint8Array.from(atob(tag), c => c.charCodeAt(0));

    const cryptoKey = await window.crypto.subtle.importKey(
        'raw',
        key,
        'AES-GCM',
        false,
        ['decrypt']
    );

    try {
        console.log("Attempting decrypt...")
        const decryptedBuffer = await window.crypto.subtle.decrypt(
            {
                name: 'AES-GCM',
                iv: ivBuffer,
                tagLength: 128, // 128-bit tag length
            },
            cryptoKey,
            new Uint8Array([...ciphertextBuffer, ...tagBuffer])
        );

        return new TextDecoder().decode(decryptedBuffer);

    } catch (error) {
        console.error('Error decrypting message:', error);
    }
};
const doubleEncrypt = async (key1, key2, plainTextMessage, flux = null) => {
    try {
        console.log("plaintext message type", typeof plainTextMessage);
        const contentObject = { "content": plainTextMessage, "flux": flux };
        // First encryption with key1
        const firstEncryption = await singleEncryptMessage(key1, JSON.stringify(contentObject));

        // Serialize the first encryption result
        const serializedFirst = JSON.stringify(firstEncryption);

        // Second encryption with key2
        const secondEncryption = await singleEncryptMessage(key2, serializedFirst);

        return secondEncryption;
    } catch (error) {
        console.error('Error in doubleEncrypt:', error);
    }
};
const doubleDecrypt = async (key1, key2, encryptedMessage) => {
    try {
        const firstDecryption = await singleDecryptMessage(key2, encryptedMessage);
        const parsedFirst = JSON.parse(firstDecryption);
        const finalMessage = await singleDecryptMessage(key1, parsedFirst);
        const finalMessageObject = JSON.parse(finalMessage);
        return finalMessageObject.content;
    } catch (error) {
        console.error('Error in doubleDecrypt:', error);
    }
};

// Add this component at the top of your file, before the App component
const ProfileModal = ({ onSubmit, countryCode, setCountryCode }) => {
    const [phoneNumber, setPhoneNumber] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [error, setError] = useState('');
    const [otpStep, setOtpStep] = useState(false);
    const [otp, setOtp] = useState('');
    const [initialInfo, setInitialInfo] = useState(null);
    const [isCodeIncorrect, setIsCodeIncorrect] = useState(false);

    const validatePhoneNumber = (number) => {
        return number.length >= 3 && !/\s/.test(number);
    };

    const validateDisplayName = (name) => {
        return name.length >= 1;
    };

    const validateOTP = (code) => {
        return /^\d{6}$/.test(code);
    };

    const handleInitialSubmit = (e) => {
        e.preventDefault();
        if (!validatePhoneNumber(phoneNumber)) {
            setError('Please enter a valid phone number');
            return;
        }
        if (!validateDisplayName(displayName)) {
            setError('Please enter a display name');
            return;
        }

        // Store initial info for later submission
        setInitialInfo({
            phoneNumber,
            displayName
        });

        // Request OTP
        onSubmit({
            type: 'otp-request',
            phoneNumber,
            displayName
        });

        // Switch to OTP step
        setOtpStep(true);
        setError('');
    };

    const handleOTPSubmit = (e) => {
        e.preventDefault();
        if (!validateOTP(otp)) {
            setError('Please enter a valid 6-digit code');
            return;
        }

        // Submit OTP for verification
        onSubmit({
            type: 'code-confirm',
            code: otp,
            ...initialInfo
        });
    };

    const handleNewCodeRequest = () => {
        // Reset the OTP state and request new code
        setOtp('');
        setError('');
        setIsCodeIncorrect(false);
        onSubmit({
            type: 'otp-request',
            phoneNumber: initialInfo.phoneNumber,
            displayName: initialInfo.displayName
        });
    };

    const handleBackToLogin = () => {
        onSubmit({ type: 'logout' });
    };

    useEffect(() => {
        const handleVerificationError = () => {
            setIsCodeIncorrect(true);
        };

        window.addEventListener('code-verification-error', handleVerificationError);
        return () => {
            window.removeEventListener('code-verification-error', handleVerificationError);
        };
    }, []);

    if (otpStep) {
        if (isCodeIncorrect) {
            return (
                <div className="modal-overlay">
                    <div className="profile-modal">
                        <h2>Incorrect Code</h2>
                        <p>You've entered an incorrect code. Would you like to try again with a new code?</p>
                        <div className="button-container">
                            <button
                                onClick={handleNewCodeRequest}
                                className="profile-modal-button"
                            >
                                Send New Code
                            </button>
                            <button
                                onClick={handleBackToLogin}
                                className="profile-modal-button secondary"
                            >
                                Back to Login
                            </button>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="modal-overlay">
                <div className="profile-modal">
                    <h2>One-time Passcode</h2>
                    <p>Enter the 6-digit code sent to your phone</p>
                    <form onSubmit={handleOTPSubmit}>
                        <div className="input-container">
                            <input
                                type="text"
                                value={otp}
                                onChange={(e) => setOtp(e.target.value.replace(/\D/g, '').slice(0, 6))}
                                placeholder="Enter 6-digit code"
                                className="text-input"
                                maxLength="6"
                            />
                        </div>
                        {error && <div className="error-message">{error}</div>}
                        <button
                            type="submit"
                            className="profile-modal-button"
                            disabled={!validateOTP(otp)}
                        >
                            Verify
                        </button>
                    </form>
                </div>
            </div>
        );
    }

    return (
        <div className="modal-overlay">
            <div className="profile-modal">
                <h2>Complete Your Profile</h2>
                <p>Please provide your information to continue</p>
                <form onSubmit={handleInitialSubmit}>
                    <div className="input-container">
                        <h3>Display Name</h3>
                        <input
                            type="text"
                            value={displayName}
                            onChange={(e) => setDisplayName(e.target.value)}
                            placeholder="Your display name"
                            className="text-input"
                        />
                    </div>
                    <div className="input-container">
                        <h3>Phone Number</h3>
                        <div className="phone-input-container">
                            <div className="country-select-container">
                                <CountrySelect
                                    value={countryCode}
                                    onChange={(value) => setCountryCode(value)}
                                />
                            </div>
                            <input
                                type="tel"
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value.replace(/\D/g, ''))}
                                placeholder="Your phone number"
                                className="phone-input"
                            />
                        </div>
                    </div>
                    {error && <div className="error-message">{error}</div>}
                    <button
                        type="submit"
                        className="profile-modal-button"
                        disabled={!validatePhoneNumber(phoneNumber) || !validateDisplayName(displayName)}
                    >
                        Submit
                    </button>
                </form>
            </div>
        </div>
    );
};

// Add this function after the appNames map
const getAppNameFromUrl = () => {
    const hostname = window.location.hostname;
    if (hostname === 'localhost') {
        return appNames['sinbi']; // Default to Sinbi in development
    }
    const parts = hostname.split('.');
    if (parts.length >= 2) {
        const appKey = parts[1].toLowerCase();
        return appNames[appKey] || 'App';
    }
    return 'App';
};

function App() {
    const [clientPhone, setClientPhone] = useState('');
    const [countryCode, setCountryCode] = useState('+1');
    const [clientEmail, setClientEmail] = useState('');
    const [userId, setUserId] = useState(null);
    const [sessionId, setSessionId] = useState(null);
    const [displayName, setdisplayName] = useState('');
    const [loggedIn, setLoggedIn] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const [notification, setNotification] = useState(null); // State for notification
    const [threads, setThreads] = useState([]); // Manage the list of threads
    const [currentThreadId, setCurrentThreadId] = useState(''); // Manage the active thread
    const [drawerWidth, setDrawerWidth] = useState(200); // Initial width for the SideDrawer
    const [isMobile, setIsMobile] = useState(false);
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const [pendingInvites, setPendingInvites] = useState([]);
    const [contacts, setContacts] = useState([]);
    const [pendingThreadId, setPendingThreadId] = useState(null);
    const pendingImages = useRef({});
    const resizerRef = useRef();
    const socketRef = useRef();
    const messageQueueRef = useRef([]);
    const messageListRef = useRef();
    const [participants, setParticipants] = useState({});
    const [participantsDetails, setParticipantsDetails] = useState({});
    const [isLoadingThread, setIsLoadingThread] = useState(true);
    const [isConnected, setIsConnected] = useState(false);
    const [isGoogleSigningIn, setIsGoogleSigningIn] = useState(false);
    const [isVerified, setIsVerified] = useState(true); // Start as true to prevent modal flash
    const [hasReceivedVerificationStatus, setHasReceivedVerificationStatus] = useState(false);

    const savedPublicKey = useRef(null);
    const nonces = useRef({});

    const isDevelopment = process.env.NODE_ENV === 'development';
    console.log('isDevelopment:', isDevelopment);

    useEffect(() => {
        if (currentThreadId && sessionId) {
            console.log('Sending update context message:', currentThreadId);
            socketRef.current?.send(
                JSON.stringify({
                    type: 'update_context',
                    sessionId: sessionId,
                    context: {  // Changed from 'context' to 'data' at the message level
                        lastSelectedThread: currentThreadId
                    }
                })
            );
        }
    }, [currentThreadId, sessionId]);

    useEffect(() => {
        const storedLoggedIn = localStorage.getItem('loggedIn');
        const storedClientPhone = localStorage.getItem('clientPhone');
        const storedCountryCode = localStorage.getItem('countryCode');
        const storedSessionId = localStorage.getItem('sessionId');
        const storedThreads = localStorage.getItem('threads');
        const storedUserId = localStorage.getItem('userId');
        const storeddisplayName = localStorage.getItem('displayName');

        // First, set all the stored values
        if (storedThreads) {
            setThreads(JSON.parse(storedThreads));
        }

        // Check if we have a valid session
        if (storedSessionId) {
            // Set all the user data first
            setLoggedIn(true);
            setClientPhone(storedClientPhone || '');
            setCountryCode(storedCountryCode || '+1');
            setSessionId(storedSessionId);
            setUserId(storedUserId);
            setdisplayName(storeddisplayName || '');

            // Then send the login message
            const sendLoginMessage = () => {
                if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
                    socketRef.current.send(
                        JSON.stringify({
                            type: 'confirm_socket',
                            sessionId: storedSessionId
                            // type: 'login',
                            // userPhone: storedClientPhone ? getFullPhoneNumber(storedClientPhone, storedCountryCode) : '',
                            // userId: storedUserId,
                            // sessionId: storedSessionId,
                        })
                    );
                    setIsLoadingThread(true);
                    console.log('SENT LOGIN REQUEST');
                } else {
                    // Queue the message if WebSocket isn't ready
                    if (socketRef.current) {
                        messageQueueRef.current.push(
                            JSON.stringify({
                                type: 'confirm_socket',
                                sessionId: storedSessionId
                                // type: 'login',
                                // userPhone: storedClientPhone ? getFullPhoneNumber(storedClientPhone, storedCountryCode) : '',
                                // userId: storedUserId,
                                // sessionId: storedSessionId,
                            })
                        );
                        setIsLoadingThread(true);
                        console.log('QUEUED LOGIN REQUEST');
                    }
                }
            };

            sendLoginMessage();
        }

        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    useEffect(() => {
        if (loggedIn) {
            localStorage.setItem('loggedIn', 'true');
            localStorage.setItem('clientPhone', clientPhone);
            localStorage.setItem('countryCode', countryCode);
            localStorage.setItem('sessionId', sessionId);
            localStorage.setItem('currentThreadId', currentThreadId);
            localStorage.setItem('userId', userId);
            localStorage.setItem('displayName', displayName);
            if (threads.length > 0) {
                localStorage.setItem('threads', JSON.stringify(threads));
            }
        } else {
            localStorage.removeItem('loggedIn');
            localStorage.removeItem('clientPhone');
            localStorage.removeItem('countryCode');
            localStorage.removeItem('sessionId');
            localStorage.removeItem('currentThreadId');
            localStorage.removeItem('userId');
            localStorage.removeItem('displayName');
            localStorage.removeItem('threads');
        }
    }, [loggedIn, clientPhone, countryCode, sessionId, userId, displayName]);

    useEffect(() => {
        const handleConnectionChange = (connected) => {
            setIsConnected(connected);
        };

        // Subscribe to connection status changes from WebSocketProvider
        if (socketRef.current) {
            socketRef.current.addEventListener('open', () => handleConnectionChange(true));
            socketRef.current.addEventListener('close', () => handleConnectionChange(false));
        }

        return () => {
            if (socketRef.current) {
                socketRef.current.removeEventListener('open', () => handleConnectionChange(true));
                socketRef.current.removeEventListener('close', () => handleConnectionChange(false));
            }
        };
    }, []);

    useEffect(() => {
        let disconnectionTimer;

        if (!isConnected && loggedIn) {
            // Wait 5 seconds before logging out to handle brief interruptions
            disconnectionTimer = setTimeout(() => {
                if (!isConnected) { // Double check we're still disconnected
                    console.log('Connection lost, logging out user...');
                    handleLogout();
                }
            }, 5000);
        }

        return () => {
            if (disconnectionTimer) {
                clearTimeout(disconnectionTimer);
            }
        };
    }, [isConnected, loggedIn]);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    const handleThreadLoaded = () => {
        setIsLoadingThread(false);
    }

    const handleMouseDown = (e) => {
        e.preventDefault();
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    }

    const handleMouseMove = (e) => {
        const newWidth = Math.min(Math.max(e.clientX, 150), 400); // Limit width between 150px and 400px
        setDrawerWidth(newWidth);
    };

    const handleMouseUp = () => {
        document.removeEventListener('mousemove', handleMouseMove);
        document.removeEventListener('mouseup', handleMouseUp);
    };

    const validatePhoneNumber = (number) => {
        // At least 3 characters and no whitespace
        return number.length >= 3 && !/\s/.test(number);
    };

    const validateEmail = (email) => {
        // eslint-disable-next-line no-useless-escape
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return emailRegex.test(email);
    };

    const nonEmptyField = (field) => {
        return field.length > 0;
    };

    const getFullPhoneNumber = (phone, cc) => {
        let functionPhone;
        let functionCountryCode;
        if (phone && cc) {
            functionPhone = phone;
            functionCountryCode = cc;
        } else {
            functionPhone = clientPhone;
            functionCountryCode = countryCode;
        }

        // Step 1: Remove all non-digits from the phone number (clientPhone)
        const cleanPhone = functionPhone.replace(/\D/g, '');

        // Step 2: Ensure the country code starts with a '+'
        const cleanCountryCode = functionCountryCode.startsWith('+') ? functionCountryCode : `+${functionCountryCode}`;

        // Step 3: Format the phone number based on country code
        let formattedPhone = cleanPhone;

        // For US numbers (country code '+1'), format as XXX XXX XXXX
        if (cleanCountryCode === '+1' && cleanPhone.length >= 10) {
            const areaCode = cleanPhone.substring(0, 3); // First 3 digits
            const prefix = cleanPhone.substring(3, 6); // Next 3 digits
            const lineNumber = cleanPhone.substring(6, 10); // Last 4 digits
            formattedPhone = `${areaCode}${prefix}${lineNumber}`;
        }

        // Step 4: Combine country code and formatted phone number with a space
        return `${cleanCountryCode} ${formattedPhone}`.trim();
    };

    const handleSubmit = () => {
        if (validatePhoneNumber(clientPhone)) {
            setClientPhone(clientPhone);
            setLoggedIn(true);
        }
    };

    const handleJoinedThread = (threadId, threadName) => {
        setThreads((prevThreads) => {
            const updatedThreads = prevThreads.some((thread) => thread.id === threadId)
                ? prevThreads
                : [...prevThreads, { id: threadId, name: threadName }];

            // Select the new thread immediately after updating threads
            // handleThreadSelectWithUpdatedThreads(threadId, updatedThreads);
            handleThreadSelect(threadId);
            return updatedThreads;
        });
    };


    // Helper function to select a thread with updated threads
    const handleThreadSelectWithUpdatedThreads = (threadId, updatedThreads) => {
        if (updatedThreads.some((thread) => thread.id === threadId)) {
            setCurrentThreadId(threadId);

            // Update the URL without reloading the page
            const newUrl = `${window.location.origin}?t=${threadId}`;
            window.history.pushState({ threadId }, '', newUrl);
        } else {
            console.warn('Attempted to switch to an invalid thread:', threadId);
        }
    };

    const handleThreadSelect = (threadId) => {
        setCurrentThreadId(threadId);
    };

    const handleNewThread = (threadName) => {
        if (socketRef.current) {
            socketRef.current.send(
                JSON.stringify({
                    type: 'new_thread',
                    threadName: threadName,
                    sessionId: sessionId,
                })
            )
        }
    };

    const handleLoginAttempt = async () => {
        console.log('Send Login Message to Server');
        const fullPhone = getFullPhoneNumber();

        if (!validatePhoneNumber(clientPhone)) {
            console.error('Invalid phone number');
            return;
        }

        if (socketRef.current) {
            socketRef.current.send(
                JSON.stringify({
                    type: 'login',
                    userPhone: fullPhone,
                })
            );
            setIsLoadingThread(true);
            console.log('SENT LOGIN REQUEST');
        }
    };

    const handleLoginResponse = (sid) => {
        console.log('Successfully logged in');

        setLoggedIn(true);
        setSessionId(sid);

        sendConfirmSocket(sid);
        // setUserId(uid);
        // setdisplayName(displayName);
    };

    const handleRegisterAttempt = async () => {
        console.log('sending register message');
        const fullPhone = getFullPhoneNumber();

        if (socketRef.current) {
            socketRef.current.send(
                JSON.stringify({
                    type: 'register',
                    userPhone: fullPhone,
                    displayName: displayName,
                    userEmail: clientEmail,
                })
            );
        }
    }

    const handleRegistrationSuccess = () => {
        setNotification('Registration Successful');
        setTimeout(() => {
            setNotification(null);
        }, 5000);
    };

    const handleClearMessages = () => {
        if (messageListRef.current) {
            messageListRef.current.clearMessages();
        }
    };


    const handleLogout = () => {
        console.log('Send Logout Message to Server');
        if (socketRef.current) {
            socketRef.current.send(
                JSON.stringify({
                    type: 'logout',
                    sessionId: sessionId,
                })
            );
        }
        // setMessages([]);
        handleClearMessages();
        setParticipants({});
        setLoggedIn(false);
        setSessionId(null);
        setThreads([]);
        setCurrentThreadId('');
        setPendingInvites([]);
    };

    const handleThreadAdded = (threadId, threadName, sessionId, lastMessageTimestamp, selectThread) => {

        setThreads((prevThreads) => {
            if (!prevThreads.find((thread) => thread.id === threadId)) {
                return [...prevThreads, { id: threadId, name: threadName, lastMessageTimestamp: lastMessageTimestamp }];
            }
            return prevThreads;
        });

        // handleThreadSelect(threadId);
        if (selectThread) {
            handleThreadSelect(threadId);
            // setPendingThreadId(threadId);
        }
        // setPendingThreadId(threadId);

    };

    const handleSendInvite = (inviteePhone, targetThreadId) => {
        if (socketRef.current) {
            socketRef.current.send(
                JSON.stringify({
                    type: 'invite_phone',
                    targetThreadId: targetThreadId,
                    inviteePhone: inviteePhone,
                    sessionId: sessionId,
                })
            );
        }
    };

    const handleAcceptInvite = (inviteId) => {
        // find the invite
        const invite = pendingInvites.find(invite => invite.id === inviteId);
        if (!invite) {
            console.error('Invite not found');
            return;
        }

        // send accept invite message
        if (socketRef.current) {
            socketRef.current.send(
                JSON.stringify({
                    type: 'invite_accept',
                    inviteId: inviteId,
                    sessionId: sessionId,
                })
            );
            // remove invite from pending invites
            setPendingInvites((prevInvites) => prevInvites.filter(invite => invite.id !== inviteId));
        }

    };

    const handleDeclineInvite = (inviteId) => {
        // find the invite
        const invite = pendingInvites.find(invite => invite.id === inviteId);
        if (!invite) {
            console.error('Invite not found');
            return;
        }

        // send decline invite message
        if (socketRef.current) {
            socketRef.current.send(
                JSON.stringify({
                    type: 'invite_decline',
                    inviteId: inviteId,
                    sessionId: sessionId,
                })
            );
            // remove invite from pending invites
            setPendingInvites((prevInvites) => prevInvites.filter(invite => invite.id !== inviteId));
        }
    };

    const handleParticipants = (threadId, participants) => {
        setParticipants((prevParticipants) => {
            return {
                ...prevParticipants,
                [threadId]: participants,
            };
        });
    };

    const handleAddParticipant = (threadId, participantId) => {
        setParticipants((prevParticipants) => {
            return {
                ...prevParticipants,
                [threadId]: [...prevParticipants[threadId], participantId],
            };
        });
    };

    const handleParticipantsDetails = (threadId, details) => {
        setParticipantsDetails((prevDetails) => {
            return {
                ...prevDetails,
                [threadId]: details,
            };
        });
    };

    const handleGoogleLogin = () => {
        setIsGoogleSigningIn(true);
        const authUrl = process.env.NODE_ENV === 'development'
            ? 'http://localhost:8080/auth/google'
            : 'https://sinbi-websocket-platform-all.azurewebsites.net/auth/google';

        window.location.href = authUrl;
    };

    // Fetch threads
    const sendConfirmSocket = (sessionId) => {
        if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
            console.log("CONFIRMING SOCKET");
            socketRef.current.send(
                JSON.stringify({
                    type: 'confirm_socket',
                    sessionId: sessionId,
                })
            );
        } else {
            // Queue the message if WebSocket isn't ready
            if (socketRef.current) {
                messageQueueRef.current.push(
                    JSON.stringify({
                        type: 'confirm_socket',
                        sessionId: sessionId,
                    })
                );
                console.log('QUEUED CONFIRM SOCKET REQUEST');
            }
        }
    };

    useEffect(() => {
        // Check for sessionId in URL params after OAuth redirect
        const params = new URLSearchParams(window.location.search);
        const oauthSessionId = params.get('sessionId');

        if (oauthSessionId) {
            setSessionId(oauthSessionId);
            setLoggedIn(true);
            // Clean up URL
            window.history.replaceState({}, document.title, window.location.pathname);



            sendConfirmSocket(oauthSessionId);
        }
    }, []);

    const handleProfileSubmit = async (data) => {
        if (data.type === 'logout') {
            handleLogout();
            return;
        }

        if (data.type === 'otp-request') {
            const { phoneNumber, displayName } = data;
            const fullPhone = getFullPhoneNumber(phoneNumber, countryCode);

            // Send OTP request to server
            if (socketRef.current) {
                socketRef.current.send(
                    JSON.stringify({
                        type: 'otp-request',
                        sessionId: sessionId,
                        phoneNumber: fullPhone,
                        displayName: displayName
                    })
                );
            }
        } else if (data.type === 'code-confirm') {
            const { code, phoneNumber, displayName } = data;
            const fullPhone = getFullPhoneNumber(phoneNumber, countryCode);

            const profileInfo = {
                phone: fullPhone,
                displayName: displayName,
                code: code
            }

            const keyGen = new MlKem768();
            const [cipherText, sharedSecret] = await keyGen.encap(base64ToUint8Array(savedPublicKey.current));

            const TSID = await onewayHash(sharedSecret + sessionId + 'key1');
            const TSID2 = await onewayHash(sharedSecret + sessionId + 'key2');

            const quantumKey1 = await deriveKey(sharedSecret, TSID);
            const quantumKey2 = await deriveKey(sharedSecret, TSID2);

            sharedSecret.fill(0);

            const encryptedProfileInfo = await doubleEncrypt(quantumKey1, quantumKey2, profileInfo);

            quantumKey1.fill(0);
            quantumKey2.fill(0);

            if (socketRef.current) {
                socketRef.current.send(
                    JSON.stringify({
                        type: 'code-confirm',
                        sessionId: sessionId,
                        profileInfo: encryptedProfileInfo,
                        cipherText: uint8ArrayToBase64(cipherText)
                    })
                );
            }
        }
    };

    useEffect(() => {
        // Add heartbeat interval
        const heartbeat = setInterval(() => {
            if (socketRef.current?.readyState === WebSocket.OPEN) {
                socketRef.current.send(JSON.stringify({ type: 'ping' }));
            }
        }, 30000); // Send heartbeat every 30 seconds

        // Clean up
        return () => {
            clearInterval(heartbeat);
            // ... existing cleanup ...
        };
    }, []);

    useEffect(() => {
        const handleMessage = (event) => {
            const message = JSON.parse(event.data);
            switch (message.type) {
                case 'otp-success':
                    // OTP was sent successfully
                    break;
                case 'otp-error':
                    // Handle OTP sending error
                    console.error('OTP Error:', message.error);
                    // You might want to show this error in the UI
                    break;
                case 'code-confirm-success':
                    // Code verified successfully
                    console.log("Code verified successfully");
                    setClientPhone(message.phone);
                    setdisplayName(message.displayName);
                    break;
                case 'code-confirm-error':
                    // Code verification failed
                    console.error('Code verification error:', message.error);
                    // You might want to show this error in the UI
                    break;
                default:
                    // Handle other message types
                    break;
            }
        };

        // Add event listener when the socket is available
        if (socketRef.current) {
            socketRef.current.addEventListener('message', handleMessage);
        }

        // Clean up the event listener when the component unmounts
        return () => {
            if (socketRef.current) {
                socketRef.current.removeEventListener('message', handleMessage);
            }
        };
    }, [socketRef.current]); // Add socketRef.current as a dependency

    // Update localStorage when isVerified changes
    useEffect(() => {
        if (loggedIn) {
            localStorage.setItem('isVerified', JSON.stringify(isVerified));
        } else {
            localStorage.removeItem('isVerified');
        }
    }, [isVerified, loggedIn]);

    if (!loggedIn) {
        return (
            <WebSocketProvider socketRef={socketRef} savedPublicKey={savedPublicKey} nonces={nonces} pendingImagesRef={pendingImages} onRegistrationSuccess={handleRegistrationSuccess} messageQueueRef={messageQueueRef} setUserId={setUserId} setContacts={setContacts} setPendingInvites={setPendingInvites} userId={userId} sessionId={sessionId} setSessionId={setSessionId} clientPhone={getFullPhoneNumber()} threadId={currentThreadId} onThreadAdded={handleThreadAdded} joinThread={handleJoinedThread} clientThreads={threads} handleLoginResponse={handleLoginResponse} isLoggedIn={loggedIn} handleParticipants={handleParticipants} handleAddParticipant={handleAddParticipant} handleParticipantsDetails={handleParticipantsDetails} handleThreadLoaded={handleThreadLoaded} onLogout={handleLogout} setIsVerified={setIsVerified} hasReceivedVerificationStatus={hasReceivedVerificationStatus} setHasReceivedVerificationStatus={setHasReceivedVerificationStatus}>
                {!showRegister && (
                    <div className="client-setup">
                        {isDevelopment && (
                            <form onSubmit={(e) => {
                                e.preventDefault();
                                // handleSubmit();
                                handleLoginAttempt();
                            }}>
                                <h2>Phone Number</h2>
                                <div className="phone-input-container">
                                    <div className="country-select-container">
                                        <CountrySelect
                                            value={countryCode}
                                            onChange={(value) => setCountryCode(value)}
                                        />
                                    </div>
                                    <input
                                        type="tel"
                                        value={clientPhone}
                                        onChange={(e) => setClientPhone(e.target.value.replace(/\D/g, ''))}
                                        placeholder="Your phone number"
                                        className="phone-input"
                                    />
                                </div>
                                <button
                                    type="submit"
                                    className="join-button"
                                    disabled={!validatePhoneNumber(clientPhone)}
                                >
                                    Login
                                </button>
                                {!validatePhoneNumber(clientPhone) && clientPhone && (
                                    <p className="error-message">
                                        Entry not valid
                                    </p>
                                )}
                                <p
                                    className="register-link"
                                    style={{ cursor: 'pointer', textDecoration: 'none', marginTop: '30px' }}
                                    onClick={() => setShowRegister(true)}
                                >
                                    Register
                                </p>
                            </form>
                        )}
                        <div className="oauth-signin-container">
                            <h1>Welcome to {getAppNameFromUrl()}</h1>
                            <div className="oauth-buttons">
                                <button
                                    onClick={handleGoogleLogin}
                                    className="google-login-button"
                                    disabled={isGoogleSigningIn}
                                >
                                    <div className="google-icon-wrapper">
                                        {isGoogleSigningIn ? (
                                            <div className="spinner"></div>
                                        ) : (
                                            <FontAwesomeIcon icon={faGoogle} />
                                        )}
                                    </div>
                                    <span className="button-text">Sign in with Google</span>
                                </button>
                            </div>
                        </div>
                    </div>
                )}
                {showRegister && (
                    <div className="client-setup">
                        <form onSubmit={(e) => {
                            e.preventDefault();
                            console.log('Registering user')
                            setShowRegister(false);
                            handleRegisterAttempt();
                        }}>
                            <h3>Display Name</h3>
                            <input
                                type="text"
                                value={displayName}
                                onChange={(e) => setdisplayName(e.target.value)}
                            // placeholder=""
                            />
                            {/* <h3>Last Name</h3>
                            <input
                                type="text"
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            // placeholder="Your phone number"
                            /> */}
                            <h3>Phone Number</h3>
                            <div className="phone-input-container">
                                <select
                                    value={countryCode}
                                    onChange={(e) => setCountryCode(e.target.value)}
                                    className="country-select"
                                >
                                    <option value="+1" className="country-option">🇺🇸 +1 (US)</option>
                                    <option value="+44" className="country-option">🇬🇧 +44 (UK)</option>
                                    <option value="+33" className="country-option">🇫🇷 +33 (FR)</option>
                                    <option value="+49" className="country-option">🇩🇪 +49 (DE)</option>
                                    <option value="+81" className="country-option">🇯🇵 +81 (JP)</option>
                                </select>
                                <input
                                    type="tel"
                                    value={clientPhone}
                                    onChange={(e) => setClientPhone(e.target.value.replace(/\D/g, ''))}
                                    placeholder="Your phone number"
                                    className="phone-input"
                                />
                            </div>
                            <h3>Email</h3>
                            <input
                                type="text"
                                value={clientEmail}
                                onChange={(e) => setClientEmail(e.target.value)}
                            // placeholder="Your phone number"
                            />
                            <button
                                type="submit"
                                className="join-button"
                                disabled={!validatePhoneNumber(clientPhone) || !nonEmptyField(displayName) || !validateEmail(clientEmail)}
                            >
                                Register
                            </button>
                            {!validatePhoneNumber(clientPhone) && clientPhone && (
                                <p className="error-message">Entry not valid</p>
                            )}
                            <p
                                className="login-link"
                                style={{ cursor: 'pointer', textDecoration: 'none', marginTop: '30px' }}
                                onClick={() => setShowRegister(false)}
                            >
                                Back to Login
                            </p>
                        </form>
                    </div>
                )}
            </WebSocketProvider>
        );
    }

    return (
        <WebSocketProvider socketRef={socketRef} savedPublicKey={savedPublicKey} nonces={nonces} pendingImagesRef={pendingImages} messageQueueRef={messageQueueRef} setUserId={setUserId} setIsVerified={setIsVerified} setContacts={setContacts} setPendingInvites={setPendingInvites} userId={userId} sessionId={sessionId} setSessionId={setSessionId} clientPhone={clientPhone} threadId={currentThreadId} onThreadAdded={handleThreadAdded} joinThread={handleJoinedThread} clientThreads={threads} handleLoginResponse={handleLoginResponse} isLoggedIn={loggedIn} handleParticipants={handleParticipants} handleAddParticipant={handleAddParticipant} handleParticipantsDetails={handleParticipantsDetails} handleThreadLoaded={handleThreadLoaded} onLogout={handleLogout} hasReceivedVerificationStatus={hasReceivedVerificationStatus} setHasReceivedVerificationStatus={setHasReceivedVerificationStatus}>
            <div className="app-container">
                <ConnectionIndicator isConnected={isConnected} />
                <div
                    className={`side-drawer-container ${isMobile ? 'mobile' : ''} ${isDrawerOpen ? 'open' : ''}`}
                    style={{ width: `${drawerWidth}px` }}
                >
                    <SideDrawer
                        threads={threads}
                        currentThread={currentThreadId}
                        onThreadSelect={handleThreadSelect}
                        pendingInvites={pendingInvites}
                        onNewThread={handleNewThread}
                        onInviteAccept={handleAcceptInvite}
                        onInviteDecline={handleDeclineInvite}
                        closeDrawer={toggleDrawer}
                        onLogout={handleLogout}
                        setPendingInvites={setPendingInvites}
                        participants={participants}
                        participantsDetails={participantsDetails}
                    />
                </div>
                {isMobile && isDrawerOpen && (
                    <div className="backdrop" onClick={toggleDrawer}></div>
                )}
                {!isMobile && (
                    <div
                        className="resizer"
                        ref={resizerRef}
                        onMouseDown={handleMouseDown}
                    />
                )}
                <div className="chat-container">
                    {isMobile && !isDrawerOpen && (
                        <FontAwesomeIcon
                            icon={faBars}
                            className="toggle-drawer-icon"
                            onClick={toggleDrawer}
                        />
                    )}
                    <MessageList ref={messageListRef} sessionId={sessionId} currentThreadId={currentThreadId} userId={userId} contacts={contacts} sendInvite={handleSendInvite} threads={threads} isLoadingThread={isLoadingThread} />
                    {currentThreadId && <MessageInput clientPhone={clientPhone} displayName={displayName} sessionId={sessionId} currentThreadId={currentThreadId} threads={threads} />}
                </div>
            </div>
            {!isVerified && hasReceivedVerificationStatus && (
                <ProfileModal
                    onSubmit={handleProfileSubmit}
                    countryCode={countryCode}
                    setCountryCode={setCountryCode}
                />
            )}
        </WebSocketProvider>
    );
}

export default App;
