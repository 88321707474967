import React, { useState, useEffect, useRef, useContext } from 'react';
import { WebSocketContext } from './WebSocketProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightToBracket, faCirclePlus, faCopy, faUser, faInbox, faUsers } from '@fortawesome/free-solid-svg-icons';
import './SideDrawer.css';

const SideDrawer = ({ threads, currentThread, onThreadSelect, onNewThread, onInviteAccept, onInviteDecline, closeDrawer, onLogout, pendingInvites, setPendingInvites, participants, participantsDetails }) => {
    const { participantsDetailsFetchv2 } = useContext(WebSocketContext);
    const [showNewChatModal, setShowNewChatModal] = useState(false);
    const [newThreadName, setNewThreadName] = useState('');
    const [showProfileDropdown, setShowProfileDropdown] = useState(false);
    const [showInboxDropdown, setShowInboxDropdown] = useState(false);
    const [selectedInvite, setSelectedInvite] = useState(null);
    const [showParticipantsModal, setShowParticipantsModal] = useState(false);
    const dropdownRef = useRef();
    const inboxRef = useRef();

    const LAST_SELECTED_THREAD_KEY = 'lastSelectedThread';

    // Close dropdowns when clicking outside
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowProfileDropdown(false);
            }
            if (inboxRef.current && !inboxRef.current.contains(event.target)) {
                setShowInboxDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);


    const handleNewChatSubmit = () => {
        if (newThreadName.trim()) {
            onNewThread(newThreadName);
            setShowNewChatModal(false);
            setNewThreadName('');
        }
    };

    const handleCopyThreadId = (threadId) => {
        navigator.clipboard.writeText(threadId).then(() => {
            console.log(`Thread ID ${threadId} copied to clipboard`);
        }).catch((err) => {
            console.error('Failed to copy thread ID:', err);
        });
    };

    const handleThreadSelect = (threadId) => {
        onThreadSelect(threadId);
        closeDrawer();
    };

    const handleInviteAction = (invite, action) => {
        if (action === 'accept') {
            onInviteAccept(invite.id);
        } else if (action === 'delete') {
            onInviteDecline(invite.id);
        }
        setSelectedInvite(null);
    };

    const handleParticipantsClick = () => {
        // participantsDetailsFetch(participants[currentThread], currentThread);
        participantsDetailsFetchv2(currentThread);
        setShowParticipantsModal(true);
    };

    const handleParticipantsClickv2 = () => {

    }

    // Assuming you have an array of threads
    const sortThreads = (threads) => {
        return threads.sort((a, b) => {
            // Always put General thread first
            if (a.name === 'General') return -1;
            if (b.name === 'General') return 1;

            // Sort by most recent message timestamp
            const aTimestamp = a.lastMessageTimestamp || 0;
            const bTimestamp = b.lastMessageTimestamp || 0;
            return bTimestamp - aTimestamp;
        });
    };

    const formatPhoneNumber = (phoneNumber) => {
        // Remove all non-numeric characters
        const cleaned = ('' + phoneNumber).replace(/\D/g, '');

        // Check if the number is a valid length
        if (cleaned.length === 10) {
            // US format: (XXX) XXX-XXXX
            return `(${cleaned.slice(0, 3)}) ${cleaned.slice(3, 6)}-${cleaned.slice(6)}`;
        } else if (cleaned.length === 11 && cleaned[0] === '1') {
            // US format with country code: +1 (XXX) XXX-XXXX
            return `+1 (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(7)}`;
        } else if (cleaned.length > 11) {
            // International format: +XX XXX XXX XXXX
            return `+${cleaned}`; // Fallback for international numbers
        }

        // Return the original number if it doesn't match any format
        return phoneNumber;
    };

    return (
        <div className="side-drawer">
            <div className="drawer-header">
                <div ref={dropdownRef}>
                    <FontAwesomeIcon
                        icon={faUser}
                        className="drawer-icon profile-icon"
                        title="Profile"
                        onClick={() => setShowProfileDropdown((prev) => !prev)}
                    />
                    {showProfileDropdown && (
                        <div className="profile-dropdown">
                            <button className="dropdown-item" onClick={onLogout}>Logout</button>
                        </div>
                    )}
                </div>

                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div ref={inboxRef} className="inbox-container">
                        <FontAwesomeIcon
                            icon={faInbox}
                            className="drawer-icon inbox-icon"
                            title="Inbox"
                            onClick={() => setShowInboxDropdown((prev) => !prev)}
                        />
                        {pendingInvites?.length > 0 && (
                            <span className="pending-invites-badge" onClick={() => setShowInboxDropdown((prev) => !prev)}>

                            </span>
                        )}
                        {showInboxDropdown && (
                            <div className="inbox-dropdown">
                                {pendingInvites?.length > 0 ? (
                                    pendingInvites.map((invite) => (
                                        <button key={invite.id} className="dropdown-item" onClick={() => setSelectedInvite(invite)}>
                                            {invite.threadName}
                                        </button>
                                    ))
                                ) : (
                                    <button className="dropdown-item no-invites">No pending invites</button>
                                )}
                            </div>
                        )}
                    </div>

                    <FontAwesomeIcon
                        icon={faCirclePlus}
                        className="drawer-icon"
                        onClick={() => setShowNewChatModal(true)}
                        title="New Chat"
                    />
                </div>
            </div>

            <ul className="thread-list">
                {sortThreads(threads).map((thread) => (
                    <li
                        key={thread.id}
                        className={thread.id === currentThread ? 'thread-item selected' : 'thread-item'}
                    >
                        <span onClick={() => handleThreadSelect(thread.id)} className="thread-name">
                            {thread.name}
                        </span>
                        {thread.id === currentThread && thread.id !== 'general' && (
                            <FontAwesomeIcon
                                icon={faUsers}
                                className="participants-icon"
                                title="View Participants"
                                onClick={handleParticipantsClick}
                            />
                        )}
                    </li>
                ))}
            </ul>

            {/* New Chat Modal */}
            {showNewChatModal && (
                <div className="modal-backdrop" onClick={() => setShowNewChatModal(false)}>
                    <div className="modal" onClick={(e) => e.stopPropagation()}>
                        <h3>Create New Tribe</h3>
                        <input
                            type="text"
                            value={newThreadName}
                            onChange={(e) => setNewThreadName(e.target.value)}
                            className="modal-name-input"
                            placeholder="Enter Tribe Name"
                            onKeyDown={(e) => e.key === 'Enter' && handleNewChatSubmit()}
                        />
                        <div className="modal-actions">
                            <button onClick={handleNewChatSubmit}>Create</button>
                            <button onClick={() => setShowNewChatModal(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Invite Modal */}
            {selectedInvite && (
                <div className="modal-backdrop" onClick={() => setSelectedInvite(null)}>
                    <div className="modal" onClick={(e) => e.stopPropagation()}>
                        <h3>Join {selectedInvite.threadName}?</h3>
                        <div className="modal-actions">
                            <button onClick={() => handleInviteAction(selectedInvite, 'accept')}>Accept</button>
                            <button onClick={() => handleInviteAction(selectedInvite, 'delete')}>Delete</button>
                        </div>
                    </div>
                </div>
            )}

            {/* Participants Modal */}
            {showParticipantsModal && (
                <div className="modal-backdrop" onClick={() => setShowParticipantsModal(false)}>
                    <div className="modal" onClick={(e) => e.stopPropagation()}>
                        <h3>Participants</h3>
                        <ul>
                            {participantsDetails[currentThread]?.map((participant, index) => (
                                <li key={index}>
                                    <span className="participant-name">{participant.name}</span>
                                    <span className="participant-phone">{formatPhoneNumber(participant?.devices?.phone)}</span>
                                </li>
                            ))}
                        </ul>
                        <div className="modal-actions">
                            <button onClick={() => setShowParticipantsModal(false)}>Close</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default SideDrawer;