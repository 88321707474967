//WebSocketProvider.js
import React, { createContext, useEffect, useRef, useState } from 'react';
import { MlKem768 } from 'mlkem';
import { toByteArray, fromByteArray } from 'base64-js';
import { Buffer } from 'buffer';

export const WebSocketContext = createContext();

const WebSocketProvider = ({ children, pendingImagesRef, onRegistrationSuccess, messageQueueRef, socketRef, savedPublicKey, nonces, setUserId, setContacts, setPendingInvites, userId, sessionId, setSessionId, clientPhone, threadId, onThreadAdded, joinThread, clientThreads, isLoggedIn, handleLoginResponse, handleParticipants, handleAddParticipant, handleParticipantsDetails, handleThreadLoaded, onLogout, setIsVerified, hasReceivedVerificationStatus, setHasReceivedVerificationStatus }) => {
    const [isReady, setIsReady] = useState(false);
    const [messages, setMessages] = useState([]);
    const quantumKeys = useRef({ key1: null, key2: null });
    const imageChunksRef = useRef({});
    // const savedPublicKey = useRef(null);
    const aesKeysRef = useRef({ key1: null, key2: null });

    // const nonces = useRef({});

    const devURL = "http://127.0.0.1:5001/sinbi-store/us-central1/api/"
    const prodURL = "https://us-central1-sinbi-store.cloudfunctions.net/api/"

    const apiURL = prodURL;

    const clearMessages = () => {
        setMessages([]);
    };

    function base64ToUint8Array(base64String) {
        return toByteArray(base64String);
    }

    function uint8ArrayToBase64(uint8Array) {
        return fromByteArray(uint8Array);
    }

    function arrayBufferToBase64(buffer) {
        const bytes = new Uint8Array(buffer);
        const chunkSize = 8192; // Process data in 8KB chunks
        let binaryString = '';

        for (let i = 0; i < bytes.length; i += chunkSize) {
            const chunk = bytes.subarray(i, i + chunkSize);
            binaryString += String.fromCharCode(...chunk);
        }

        return btoa(binaryString); // Convert the binary string to Base64
    }

    async function getSinbiPublicKey(nonce) {
        try {
            console.log("GET_PUBLIC_KEY nonce", nonce);
            // const response = await fetch(apiURL + "get_public_key");
            const response = await fetch(apiURL + "get_public_key", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({ nonce: nonce }),
            });
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Error: ${response.status} - ${errorText}`);
            }

            const responseData = await response.json();
            const returnedPublicKey = await base64ToUint8Array(responseData.publicKey);
            return returnedPublicKey;
        } catch (error) {
            console.error("Get failed:", error);
            throw error;
        }
    }

    // return public as uint8array
    async function oldGetSinbiPublicKey() {
        try {
            const response = await fetch(apiURL + "get_public_key")
            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Error: ${response.status} - ${errorText}`);
            }

            const responseData = await response.json();
            const returnedPublicKey = await base64ToUint8Array(responseData.publicKey);
            return returnedPublicKey;
        } catch (error) {
            console.error("Get failed:", error);
            throw error;
        }
    }

    async function sinbiPut(id, type, data, isImage = false) {
        try {
            const nonce = generatePKNonce();
            const apiPublicKey = await getSinbiPublicKey(nonce);

            console.log("PUT nonce", nonce);

            let headers = {};
            let body;

            //generate AES keys and encrypt data
            const keyGen = new MlKem768();
            const [cipherText, sharedSecret] = await keyGen.encap(apiPublicKey);

            const TSID = await onewayHash(sharedSecret + sessionId + 'key1');
            const TSID2 = await onewayHash(sharedSecret + sessionId + 'key2');

            const quantumKey1 = await deriveKey(sharedSecret, TSID);
            const quantumKey2 = await deriveKey(sharedSecret, TSID2);



            // Remove sharedSecret from memory
            sharedSecret.fill(0);


            if (isImage) {
                console.log("Processing image data...");

                //Convert File object to ArrayBuffer
                const arrayBuffer = await data.arrayBuffer();

                // Convert ArrayBuffer to Base64
                const base64StringAlt = arrayBufferToBase64(arrayBuffer);

                const encryptedData = await doubleEncrypt(quantumKey1, quantumKey2, base64StringAlt);

                console.log("Image after encryption:", encryptedData);
                console.log("CipherText length:", encryptedData.ciphertext.length);

                // For images, use multipart form-data
                const formData = new FormData();
                formData.append("id", id);
                formData.append("type", type);
                formData.append("nonce", nonce);
                formData.append("sid", sessionId);
                formData.append("cipherText", Buffer.from(cipherText).toString('base64'));
                formData.append("file", new Blob([JSON.stringify(encryptedData)], { type: "image/jpeg" })); // `data` should be a File or Blob

                body = formData;
            } else {
                // For JSON payloads
                const encryptedData = await doubleEncrypt(quantumKey1, quantumKey2, JSON.stringify(data));

                headers["Content-Type"] = "application/json";
                body = JSON.stringify({
                    id,
                    type,
                    nonce,
                    sid: sessionId,
                    cipherText: Buffer.from(cipherText).toString('base64'),
                    json: encryptedData, // Wrap the JSON payload
                });
            }

            console.log("Sending data to /put endpoint...");
            const response = await fetch(apiURL + "put", {
                method: "POST",
                headers,
                body,
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Error: ${response.status} - ${errorText}`);
            }

            const responseData = await response.json();
            return responseData;
        } catch (error) {
            console.error("Put failed:", error);
            throw error;
        }
    }

    async function sinbiGet(id, type, extension) {
        try {
            const nonce = generatePKNonce();
            const apiPublicKey = await getSinbiPublicKey(nonce);

            //generate AES keys and encrypt data
            const keyGen = new MlKem768();
            const [cipherText, sharedSecret] = await keyGen.encap(apiPublicKey);

            const TSID = await onewayHash(sharedSecret + sessionId + 'key1');
            const TSID2 = await onewayHash(sharedSecret + sessionId + 'key2');

            const quantumKey1 = await deriveKey(sharedSecret, TSID);
            const quantumKey2 = await deriveKey(sharedSecret, TSID2);

            // Remove sharedSecret from memory
            sharedSecret.fill(0);

            const payload = {
                id,
                type,
                nonce,
                sid: sessionId,
                extension, // Should be 'JSON' or 'JPG'
                'cipherText': uint8ArrayToBase64(cipherText) // Required for encryption key derivation
            };

            console.log('Payload:', payload);

            console.log("Sending data to /get endpoint...");
            const response = await fetch(apiURL + 'get', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
                mode: "cors",
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Error: ${response.status} - ${errorText}`);
            }

            // Parse the JSON response containing encrypted data
            const encryptedResponse = await response.json();

            console.log("Encrypted response received:", encryptedResponse);

            // Decrypt the response
            const decryptedBase64 = await doubleDecrypt(quantumKey1, quantumKey2, encryptedResponse);

            // Remove quantum keys from memory
            quantumKey1.fill(0);
            quantumKey2.fill(0);

            const contentType = response.headers.get("Content-Type");
            if (contentType.includes("application/json")) {
                // Parse JSON response
                const jsonContent = JSON.parse(decryptedBase64);
                return jsonContent;
            } else if (contentType.includes("image/jpeg")) {
                // Handle image response
                const imageBuffer = Buffer.from(
                    Uint8Array.from(atob(decryptedBase64), (c) => c.charCodeAt(0))
                );

                console.log("Image Buffer generated:", imageBuffer);
                return imageBuffer; // Return the image Buffer
            } else {
                throw new Error("Unexpected Content-Type received");
            }


        } catch (error) {
            console.error("Get failed:", error);
            throw error;
        }
    }


    async function oldSinbiGet(id, type, extension) {
        try {
            const payload = {
                id,
                type,
                extension, // Should be 'JSON' or 'JPG'
            };

            console.log("Sending data to /get endpoint...");
            const response = await fetch(apiURL + 'get', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(payload),
                mode: "cors",
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Error: ${response.status} - ${errorText}`);
            }

            const contentType = response.headers.get("Content-Type");
            if (contentType.includes("application/json")) {
                // Parse JSON response
                const data = await response.json();
                return data;
            } else if (contentType.includes("image/jpeg")) {
                // Handle image response
                const arrayBuffer = await response.arrayBuffer();
                const buffer = Buffer.from(arrayBuffer); // Convert ArrayBuffer to Buffer
                return buffer;
            } else {
                throw new Error("Unexpected Content-Type received");
            }
        } catch (error) {
            console.error("Get failed:", error);
            throw error;
        }
    }

    async function oldSinbiPut(id, type, data, isImage = false) {
        try {
            let headers = {};
            let body;

            if (isImage) {
                // For images, use multipart form-data
                const formData = new FormData();
                formData.append("id", id);
                formData.append("type", type);
                formData.append("file", new Blob([data], { type: "image/jpeg" })); // `data` should be a File or Blob

                body = formData;
            } else {
                // For JSON payloads
                headers["Content-Type"] = "application/json";
                body = JSON.stringify({
                    id,
                    type,
                    json: JSON.stringify(data), // Wrap the JSON payload
                });
            }

            console.log("Sending data to /put endpoint...");
            const response = await fetch(apiURL + 'put', {
                method: "POST",
                headers,
                body,
                mode: "cors",
            });

            if (!response.ok) {
                const errorText = await response.text();
                throw new Error(`Error: ${response.status} - ${errorText}`);
            }

            const responseData = await response.json();
            return responseData;
        } catch (error) {
            console.error("Put failed:", error);
            throw error;
        }
    }

    const onewayHash = async (input) => {
        const encoder = new TextEncoder();
        const data = encoder.encode(input);
        const hashBuffer = await window.crypto.subtle.digest('SHA-512', data);
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
        return hashHex;
    };

    const deriveKey = async (inputSharedSecret, salt, length = 32) => {
        const sharedSecretBuffer = new TextEncoder().encode(inputSharedSecret);
        const saltBuffer = new TextEncoder().encode(salt);

        const keyMaterial = await window.crypto.subtle.importKey(
            'raw',
            sharedSecretBuffer,
            { name: 'HKDF' },
            false,
            ['deriveKey']
        );

        const derivedKey = await window.crypto.subtle.deriveKey(
            {
                name: 'HKDF',
                hash: 'SHA-256',
                salt: saltBuffer,
                info: new Uint8Array(),
            },
            keyMaterial,
            { name: 'AES-GCM', length: length * 8 },
            true,
            ['encrypt', 'decrypt']
        );

        const keyBuffer = await window.crypto.subtle.exportKey('raw', derivedKey);
        return new Uint8Array(keyBuffer);
    };

    const generateNonce = () => {
        const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let nonce = '';
        const randomValues = new Uint8Array(89);

        window.crypto.getRandomValues(randomValues);

        for (let i = 0; i < 89; i++) {
            nonce += chars[randomValues[i] % chars.length];
        }

        return nonce;
    };

    const generatePKNonce = () => {
        const randomBytes = new Uint8Array(67); // 67 bytes to get ~89 base64 characters
        window.crypto.getRandomValues(randomBytes);

        let nonce = btoa(String.fromCharCode(...randomBytes))
            .replace(/[^a-zA-Z0-9]/g, ''); // Remove non-alphanumeric characters

        while (nonce.length < 89) {
            nonce += generatePKNonce(); // Recursively generate more if needed
        }

        return nonce.substring(0, 89); // Ensure exact length
    };

    function generateId() {
        return Array.from({ length: 89 }, () =>
            Math.random().toString(36).charAt(2)
        ).join('');
    }

    const getMicrotime = () => {
        return Math.floor(performance.now() * 1000); // Microseconds
    };

    const singleEncryptMessage = async (key, plainTextMessage) => {
        const iv = window.crypto.getRandomValues(new Uint8Array(12)); // Generate a 12-byte random IV
        const encoder = new TextEncoder();
        const plainTextBuffer = encoder.encode(plainTextMessage);

        const cryptoKey = await window.crypto.subtle.importKey(
            'raw',
            key,
            'AES-GCM',
            false,
            ['encrypt']
        );

        try {
            console.log("Attempting encryption...");
            const encryptedBuffer = await window.crypto.subtle.encrypt(
                {
                    name: 'AES-GCM',
                    iv: iv,
                    tagLength: 128, // 128-bit tag length
                },
                cryptoKey,
                plainTextBuffer
            );

            // Extract ciphertext and tag from the encrypted buffer
            const ciphertextBuffer = encryptedBuffer.slice(0, encryptedBuffer.byteLength - 16); // Last 16 bytes are the tag
            const tagBuffer = encryptedBuffer.slice(encryptedBuffer.byteLength - 16);

            // Chunked Base64 encoding for ciphertext and tag
            const chunkedBase64Encode = (buffer) => {
                const bytes = new Uint8Array(buffer);
                const chunkSize = 8192; // Process in 8KB chunks
                let binaryString = '';

                for (let i = 0; i < bytes.length; i += chunkSize) {
                    binaryString += String.fromCharCode(...bytes.subarray(i, i + chunkSize));
                }

                return btoa(binaryString);
            };

            return {
                ciphertext: chunkedBase64Encode(ciphertextBuffer),
                iv: chunkedBase64Encode(iv),
                tag: chunkedBase64Encode(tagBuffer),
            };
        } catch (error) {
            console.error('Error encrypting message:', error);
        }
    };

    const singleDecryptMessage = async (key, encryptedMessage) => {
        const { ciphertext, iv, tag } = encryptedMessage;

        const ciphertextBuffer = Uint8Array.from(atob(ciphertext), c => c.charCodeAt(0));
        const ivBuffer = Uint8Array.from(atob(iv), c => c.charCodeAt(0));
        const tagBuffer = Uint8Array.from(atob(tag), c => c.charCodeAt(0));

        const cryptoKey = await window.crypto.subtle.importKey(
            'raw',
            key,
            'AES-GCM',
            false,
            ['decrypt']
        );

        try {
            console.log("Attempting decrypt...")
            const decryptedBuffer = await window.crypto.subtle.decrypt(
                {
                    name: 'AES-GCM',
                    iv: ivBuffer,
                    tagLength: 128, // 128-bit tag length
                },
                cryptoKey,
                new Uint8Array([...ciphertextBuffer, ...tagBuffer])
            );

            return new TextDecoder().decode(decryptedBuffer);

        } catch (error) {
            console.error('Error decrypting message:', error);
        }
    };

    const doubleEncrypt = async (key1, key2, plainTextMessage, flux = null) => {
        try {
            console.log("plaintext message type", typeof plainTextMessage);
            const contentObject = { "content": plainTextMessage, "flux": flux };
            // First encryption with key1
            const firstEncryption = await singleEncryptMessage(key1, JSON.stringify(contentObject));

            // Serialize the first encryption result
            const serializedFirst = JSON.stringify(firstEncryption);

            // Second encryption with key2
            const secondEncryption = await singleEncryptMessage(key2, serializedFirst);

            return secondEncryption;
        } catch (error) {
            console.error('Error in doubleEncrypt:', error);
        }
    };

    const doubleDecrypt = async (key1, key2, encryptedMessage) => {
        try {
            const firstDecryption = await singleDecryptMessage(key2, encryptedMessage);
            const parsedFirst = JSON.parse(firstDecryption);
            const finalMessage = await singleDecryptMessage(key1, parsedFirst);
            const finalMessageObject = JSON.parse(finalMessage);
            return finalMessageObject.content;
        } catch (error) {
            console.error('Error in doubleDecrypt:', error);
        }
    };

    const handleWebSocketClose = (event) => {
        if (event.code === 1006) {
            console.log("WebSocket closed by the server");
            alert('Your session has expired. Please log in again.')
            onLogout();
        }
    }

    useEffect(() => {
        const wsUrl = window.location.hostname === 'localhost'
            ? `ws://localhost:8080`
            : `wss://sinbi-websocket-platform-all.azurewebsites.net`;

        // Load sessionId from localStorage or create a new one
        // let storedSessionId = localStorage.getItem('sessionId');
        // setSessionId(storedSessionId);

        console.log('WebSocket URL:', wsUrl);

        socketRef.current = new WebSocket(wsUrl);
        socketRef.current.isReady = false;

        socketRef.current.onopen = async () => {
            console.log('WebSocket connected');
            socketRef.current.isReady = true;

            // Clear messages if reconnecting
            setMessages([]);

            messageQueueRef.current.forEach((message) => {
                socketRef.current.send(message)
            });

            messageQueueRef.current = [];

        };

        socketRef.current.onclose = handleWebSocketClose;

        socketRef.current.onmessage = async (event) => {
            try {
                const message = JSON.parse(event.data);

                if (message.type === 'thread_created') {
                    console.log("===== THREAD CREATED =====");
                    onThreadAdded(message.threadId, message.threadName, message.sessionId, message.lastMessageTimestamp, message.selectThread);

                } else if (message.type === 'thread_joined') {
                    if (!clientThreads.find((thread) => thread.id === message.threadId)) {
                        console.log("===== THREAD JOINED =====");
                        //add messages from thread
                        setMessages((prev) => [...prev, ...message.messages]);
                        //handle thread participants
                        handleParticipants(message.threadId, message.participants);
                        //only join general if there is no other currentThreadId set
                        console.log("message.lastMessageTimestamp", message.lastMessageTimestamp);
                        console.log("message.selectThread", message.selectThread);
                        onThreadAdded(message.threadId, message.threadName, message.sessionId, message.lastMessageTimestamp, message.selectThread);
                    }
                } else if (message.type === 'threads_loaded') {
                    console.log("===== THREADS LOADED =====");
                    const { nonce, sessionId } = message;

                    const TSID = await onewayHash(nonces.current[nonce].ss + sessionId + 'key1');
                    const TSID2 = await onewayHash(nonces.current[nonce].ss + sessionId + 'key2');

                    const key1 = await deriveKey(nonces.current[nonce].ss, TSID);
                    const key2 = await deriveKey(nonces.current[nonce].ss, TSID2);

                    delete nonces.current[nonce];

                    let decryptedAllData = await doubleDecrypt(key1, key2, message.allData);
                    decryptedAllData = JSON.parse(decryptedAllData);

                    const { threads, data, invites, uid } = decryptedAllData;

                    setUserId(uid);

                    key1.fill(0);
                    key2.fill(0);

                    const allLoadedMessages = threads.reduce((acc, thread) => [...acc, ...(thread.messages || [])], []);
                    setMessages((prev) => [...prev, ...allLoadedMessages]);

                    const lastSelectedThread = data?.context?.lastSelectedThread;

                    console.log("lastSelectedThread", lastSelectedThread);
                    threads.forEach((thread) => {
                        let selectThisThread = thread.selectThread;
                        if (lastSelectedThread === thread.threadId) {
                            selectThisThread = true;
                        }

                        else if (!lastSelectedThread && thread.threadId === "general") {
                            selectThisThread = true;
                        }

                        onThreadAdded(thread.threadId, thread.threadName, thread.sessionId, thread.lastMessageTimestamp, selectThisThread);
                    });

                    handleThreadLoaded();

                    setPendingInvites(invites);

                } else if (message.type === 'participant_joined') {
                    const { threadId, participantId } = message;
                    console.log("===== PARTICIPANT JOINED =====");
                    handleAddParticipant(threadId, participantId);
                } else if (message.type === 'login_success') {
                    console.log("===== LOGIN SUCCESS =====");
                    console.log("=========================")
                    const { sid, publicKey } = message;


                    savedPublicKey.current = publicKey;


                    handleLoginResponse(sid);
                } else if (message.type === 'confirm_socket_success') {
                    console.log("===== CONFIRM SOCKET SUCCESS =====");
                    const { publicKey, sessionId, isVerified } = message;
                    savedPublicKey.current = publicKey;
                    console.log("publicKey", publicKey);

                    // Set verification status and mark that we've received it
                    setIsVerified(isVerified);
                    setHasReceivedVerificationStatus(true);

                    // Fetch threads
                    const keyGen = new MlKem768();
                    const [cipherText, sharedSecret] = await keyGen.encap(base64ToUint8Array(publicKey));

                    const nonce = generateNonce();

                    nonces.current[nonce] = { microtime: getMicrotime(), ss: sharedSecret };

                    socketRef.current.send(
                        JSON.stringify({
                            type: 'fetch_threads',
                            nonce: nonce,
                            sessionId: sessionId,
                            cipherText: uint8ArrayToBase64(cipherText),
                        })
                    );

                } else if (message.type === 'register_success') {
                    console.log("===== REGISTER SUCCESS =====");
                    onRegistrationSuccess();
                } else if (message.type === 'contacts') {
                    console.log("===== CONTACTS =====");
                    setContacts(message.contacts);
                } else if (message.type === 'invite_inbox') {
                    console.log("===== INVITE INBOX =====");
                    setPendingInvites(message.invites);
                } else if (message.type === 'init') { // && !savedSharedSecret.current) {
                    console.log("===== INIT =====");

                    setSessionId(message.sessionId);
                    const keyEncap = new MlKem768();
                    const [cipherText, sharedSecret] = await keyEncap.encap(
                        base64ToUint8Array(message.publicKey)
                    );

                    // savedSharedSecret.current = sharedSecret;
                    console.log("Sending encapsulated key...");
                    socketRef.current.send(
                        JSON.stringify({
                            type: 'encapsulation',
                            cipherText: uint8ArrayToBase64(cipherText),
                            sessionId: message.sessionId,
                        })
                    );
                } else if (message.type === 'chatKeys') {
                    // TO DO - check if used and remove if not
                    console.log("===== CHAT KEYS =====");

                    const encryptedMessage = message.encryptedMessage;

                    const decryptedKeys = await doubleDecrypt(
                        quantumKeys.current.key1,
                        quantumKeys.current.key2,
                        encryptedMessage
                    );

                    const parsedKeys = JSON.parse(decryptedKeys);
                    aesKeysRef.current = {
                        key1: base64ToUint8Array(parsedKeys.key1),
                        key2: base64ToUint8Array(parsedKeys.key2),
                    };
                    setSessionId(parsedKeys.sessionId);


                    socketRef.current.send(
                        JSON.stringify({
                            type: 'chatKeysAck',
                            sessionId: parsedKeys.sessionId,
                        })
                    );

                } else if (message.type === 'incoming_chat') {
                    console.log("===== INCOMING CHAT =====");
                    const { nonce, sessionId } = message;
                    // const incomingSessionId = message.sessionId;

                    const keyEncap = new MlKem768();
                    let [cipherText, sharedSecret] = await keyEncap.encap(
                        base64ToUint8Array(savedPublicKey.current)
                    );

                    nonces.current[nonce] = { nonce, microtime: getMicrotime(), ss: sharedSecret };

                    console.log('Sending chat_ack...');

                    socketRef.current.send(JSON.stringify({
                        type: 'chat_ack',
                        nonce,
                        sessionId,
                        cipherText: uint8ArrayToBase64(cipherText),
                        microtime: getMicrotime()
                    }));

                } else if (message.type === 'receive_chat') {
                    console.log("===== RECEIVE CHAT =====");
                    const { nonce } = message;
                    // const { ss } = nonces.current[nonce];

                    let key1 = await deriveKey(nonces.current[nonce].ss, 'yZSNwCJJBfBUpdcpqP0j0lesk1dRtHBKdq');
                    let key2 = await deriveKey(nonces.current[nonce].ss, 'hMJnbTx8Ns6CzGLVqrTV26ZaowwCuvmQjQ');

                    nonces.current[nonce].ss.fill(0); // Clear the shared secret
                    nonces.current[nonce].ss = null;

                    const decryptedMessage = await doubleDecrypt(
                        key1,
                        key2,
                        message.text
                    );

                    key1.fill(0); // Clear the key
                    key2.fill(0); // Clear the key
                    key1 = null;
                    key2 = null;

                    const completedMessage = {
                        ...message,
                        text: decryptedMessage,
                    };
                    setMessages((prev) => [...prev, completedMessage]);
                    delete nonces.current[nonce];
                } else if (message.type === 'receive_invite') {
                    console.log("===== RECEIVE INVITE =====");
                    setPendingInvites((prev) => [...prev, { id: message.id, threadId: message.threadId, threadName: message.threadName }]);

                } else if (message.type === 'participants_details') {
                    console.log("===== PARTICIPANTS DETAILS =====");
                    console.log("Participants:", message.participantsDetails);
                    handleParticipantsDetails(message.threadId, message.participantsDetails);
                } else if (message.type === 'participants_details_v2') {
                    console.log("===== PARTICIPANTS DETAILS V2 =====");
                    const { nonce, sessionId, threadId, participantsDetails } = message;

                    //Decrypt the participants details
                    let TSID = await onewayHash(nonces.current[nonce].ss + sessionId + 'key1');
                    let TSID2 = await onewayHash(nonces.current[nonce].ss + sessionId + 'key2');

                    let quantumKey1 = await deriveKey(nonces.current[nonce].ss, TSID);
                    let quantumKey2 = await deriveKey(nonces.current[nonce].ss, TSID2);

                    // Remove nonce from nonces
                    delete nonces.current[nonce];

                    const decryptedDetails = await doubleDecrypt(quantumKey1, quantumKey2, participantsDetails);

                    // Remove quantum keys from memory
                    quantumKey1.fill(0);
                    quantumKey2.fill(0);

                    console.log("Decrypted participants details:", decryptedDetails);

                    handleParticipantsDetails(threadId, JSON.parse(decryptedDetails));

                } else if (message.type === 'chat' && message.threadId === threadId) {
                    console.log("===== CHAT =====");
                    const decryptedMessage = await doubleDecrypt(
                        aesKeysRef.current.key1,
                        aesKeysRef.current.key2,
                        message.text
                    );
                    const completedMessage = {
                        ...message,
                        text: decryptedMessage,
                    };
                    setMessages((prev) => [completedMessage, ...prev]);
                } else if (message.type === 'image_chunk' && message.threadId === threadId) {
                    console.log("===== IMAGE =====");
                    const { threadId, sender, sessionId, imageChunk, chunkIndex, totalChunks } = message;

                    // Initialize the image chunks array if it doesn't exist
                    if (!imageChunksRef.current[threadId]) {
                        imageChunksRef.current[threadId] = { chunks: [], totalChunks, sender, sessionId };
                    }

                    // Decrypt the image chunk
                    const decryptedChunk = await doubleDecrypt(
                        aesKeysRef.current.key1,
                        aesKeysRef.current.key2,
                        imageChunk
                    );
                    // Store the decrypted chunk
                    const threadData = imageChunksRef.current[threadId];
                    threadData.chunks[chunkIndex] = decryptedChunk;

                    // If all chunks have been received, reassemble the image
                    if (threadData.chunks.filter(Boolean).length === totalChunks) {
                        const fullImage = threadData.chunks.join('');
                        const completedMessage = {
                            ...message,
                            image: fullImage,
                        };
                        setMessages((prev) => [completedMessage, ...prev]);
                        delete imageChunksRef.current[threadId]; // Cleanup
                    }

                } else if (message.type === 'incoming_image') {
                    console.log("===== INCOMING IMAGE =====");
                    const { imageId, sid, senderUserId, sender, threadId, datetime } = message;

                    pendingImagesRef.current[imageId] = { senderUserId, sender, threadId };

                    setMessages((prev) => [
                        ...prev,
                        {
                            isImage: true,
                            imageId,
                            threadId,
                            sender,
                            userId: senderUserId,
                            placeholder: true,
                            datetime
                        }
                    ])

                } else if (message.type === 'receive_image_chunk') {
                    console.log("===== RECEIVE IMAGE CHUNK =====");
                    const { imageId, imageChunk, chunkIndex, totalChunks } = message;
                    //check if chunks array exists and set if not
                    if (!pendingImagesRef.current[imageId].chunks) {
                        pendingImagesRef.current[imageId] = { ...pendingImagesRef.current[imageId], chunks: new Array(totalChunks) };
                    }
                    const { chunks } = pendingImagesRef.current[imageId];

                    let ss = pendingImagesRef.current[imageId].ss;

                    let key1 = await deriveKey(ss, `image-${imageId}-chunk-${chunkIndex}-key1`);
                    let key2 = await deriveKey(ss, `image-${imageId}-chunk-${chunkIndex}-key2`);

                    ss.fill(0); // Clear the shared secret
                    ss = null;

                    let decryptedChunk;
                    try {
                        decryptedChunk = await doubleDecrypt(
                            key1,
                            key2,
                            imageChunk
                        );
                    } finally {
                        key1.fill(0); // Clear the key
                        key2.fill(0); // Clear the key
                        key1 = null;
                        key2 = null;
                    }


                    chunks[chunkIndex] = decryptedChunk;

                    if (chunks.filter(Boolean).length === totalChunks) {
                        console.log("Finished fetching image")
                        const fullImage = chunks.join('');
                        // replace message with full image
                        setMessages((prev) => prev.map((msg) => {
                            if (msg.imageId === imageId) {
                                if (msg.holding) {
                                    console.log("Displaying image...");
                                    return {
                                        ...msg,
                                        image: fullImage,
                                        placeholder: false,
                                    };
                                } else {
                                    console.log("User not holding, image will not be displayed")
                                    return { ...msg, image: null, placeholder: true }
                                }
                            }
                            return msg;
                        }));
                        delete pendingImagesRef.current[imageId];
                    }
                } else if (message.type === 'oauth_login_success') {
                    const { uid, displayName, email, picture } = message;
                    handleLoginResponse(uid, sessionId, displayName);
                    // Store additional user info if needed
                    localStorage.setItem('userEmail', email);
                    localStorage.setItem('userPicture', picture);
                } else if (message.type === 'code-confirm-success') {
                    console.log("===== CODE CONFIRM SUCCESS =====");
                    setIsVerified(true);  // Update verification status on successful confirmation
                } else if (message.type === 'code-confirm-error') {
                    if (message.error === 'incorrect_code') {
                        // Set the incorrect code state in the modal
                        const event = new CustomEvent('code-verification-error');
                        window.dispatchEvent(event);
                    }
                }
            } catch (error) {
                console.error('Error handling WebSocket message:', error);
            }
        };

        socketRef.current.onclose = () => {
            console.log('WebSocket disconnected');
            socketRef.current.isReady = false;
        };

        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
        };
    }, []); // Reconnect or filter based on threadId

    const sendMessage = async (message, type = 'chat') => {
        console.log('Attempting to send message');

        if (type === 'chat') {
            if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
                const keyEncap = new MlKem768();
                let [cipherText, sharedSecret] = await keyEncap.encap(
                    base64ToUint8Array(savedPublicKey.current)
                );

                let key1 = await deriveKey(sharedSecret, 'yZSNwCJJBfBUpdcpqP0j0lesk1dRtHBKdq');
                let key2 = await deriveKey(sharedSecret, 'hMJnbTx8Ns6CzGLVqrTV26ZaowwCuvmQjQ');

                sharedSecret.fill(0); // Clear the shared secret
                sharedSecret = null;

                const encryptedMessage = await doubleEncrypt(
                    key1,
                    key2,
                    message.text
                );

                key1.fill(0); // Clear the key
                key2.fill(0); // Clear the key
                key1 = null;
                key2 = null;

                const nonce = generateNonce();

                console.log("Sending chat message...");

                const messageObject = {
                    text: encryptedMessage,
                    type: type || 'chat',
                    threadId, // Include threadId
                    userId,
                    sessionId,
                    sender: message.sender || clientPhone,
                    cipherText: uint8ArrayToBase64(cipherText),
                    nonce: uint8ArrayToBase64(nonce),
                    microtime: getMicrotime(),
                    datetime: new Date().toISOString(),
                };

                // set messages for sender
                setMessages((prev) => [...prev, { ...messageObject, text: message.text }]);

                socketRef.current.send(JSON.stringify(messageObject));
            }
        } else if (type === 'image') {
            const imageId = generateId();

            // send image to put endpoint

            const putResponse = await sinbiPut(imageId, 'vault', message.image, true);

            console.log('Image put response:', putResponse);
            console.log('Image sent to put endpoint');

            //send image info to ws server
            if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
                socketRef.current.send(JSON.stringify({
                    type: 'image',
                    imageId,
                    threadId,
                    sender: message.sender || clientPhone,
                    sessionId,
                    datetime: new Date().toISOString(),
                })); // send image info
            }

            if (false && socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
                const chunkSize = 8000; // Set an appropriate chunk size
                const totalChunks = Math.ceil(message.image.length / chunkSize);

                const nonce = generateNonce();

                for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
                    const chunk = message.image.slice(
                        chunkIndex * chunkSize,
                        (chunkIndex + 1) * chunkSize
                    );

                    const keyEncap = new MlKem768();
                    let [cipherText, sharedSecret] = await keyEncap.encap(
                        base64ToUint8Array(savedPublicKey.current)
                    );

                    let key1 = await deriveKey(sharedSecret, 'yZSNwCJJBfBUpdcpqP0j0lesk1dRtHBKdq');
                    let key2 = await deriveKey(sharedSecret, 'hMJnbTx8Ns6CzGLVqrTV26ZaowwCuvmQjQ');

                    sharedSecret.fill(0); // Clear the shared secret
                    sharedSecret = null;

                    const encryptedChunk = await doubleEncrypt(key1, key2, chunk);

                    key1.fill(0); // Clear the key
                    key2.fill(0); // Clear the key
                    key1 = null;
                    key2 = null;

                    const messageObject = {
                        imageChunk: encryptedChunk,
                        type: 'image_chunk',
                        threadId, // Include threadId
                        sessionId,
                        userId,
                        sender: clientPhone,
                        cipherText: uint8ArrayToBase64(cipherText),
                        nonce: uint8ArrayToBase64(nonce),
                        microtime: getMicrotime(),
                        chunkIndex,
                        totalChunks,
                    };

                    socketRef.current.send(JSON.stringify(messageObject));
                }

            }
        }
    };

    const imageFetch = async (imageId) => {
        console.log('Image fetch initiated...');
        try {
            const imageBuffer = await sinbiGet(imageId, 'vault', 'JPG');
            return imageBuffer;
        } catch (error) {
            console.error('Error fetching image:', error);
        }

    };

    const participantsDetailsFetchv2 = async (threadId) => {
        console.log('Fetching participant info...');
        try {
            const keyEncap = new MlKem768();
            let [cipherText, sharedSecret] = await keyEncap.encap(
                base64ToUint8Array(savedPublicKey.current)
            );

            const participantsNonce = generateNonce();

            nonces.current[participantsNonce] = { ss: sharedSecret, microtime: getMicrotime() };

            if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
                socketRef.current.send(JSON.stringify({
                    type: 'participants_details_v2',
                    threadId,
                    sessionId,
                    'nonce': participantsNonce,
                    'cipherText': uint8ArrayToBase64(cipherText)
                }));
            }
        } catch (error) {
            console.error('Error fetching participants:', error);
        }
    };

    const participantsDetailsFetch = async (participants, threadId) => {
        console.log('Participants fetch initiated...');
        try {

            if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
                socketRef.current.send(JSON.stringify({
                    type: 'participants_details',
                    participants,
                    sessionId,
                    threadId
                }));
            };
        } catch (error) {
            console.error('Error fetching participants:', error);
        }
    }

    return (
        <WebSocketContext.Provider value={{ messages, setMessages, clearMessages, sendMessage, imageFetch, participantsDetailsFetchv2, aesKeys: aesKeysRef.current }}>
            {children}
        </WebSocketContext.Provider>
    );
};

export default WebSocketProvider;
