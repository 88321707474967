import React, { useState, useEffect, useRef } from 'react';
import './CountrySelect.css';

const CountrySelect = ({ value, onChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    const countries = [
        { code: '+1', flag: '🇺🇸', name: 'US' },
        { code: '+44', flag: '🇬🇧', name: 'UK' },
        { code: '+33', flag: '🇫🇷', name: 'FR' },
        { code: '+49', flag: '🇩🇪', name: 'DE' },
        { code: '+81', flag: '🇯🇵', name: 'JP' },
    ];

    const selectedCountry = countries.find(c => c.code === value) || countries[0];

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="custom-select" ref={dropdownRef}>
            <div className="select-header" onClick={() => setIsOpen(!isOpen)}>
                <span className="flag">{selectedCountry.flag}</span>
                <span className="country-code">{selectedCountry.code} ({selectedCountry.name})</span>
            </div>
            {isOpen && (
                <div className="select-options">
                    {countries.map((country) => (
                        <div
                            key={country.code}
                            className="select-option"
                            onClick={() => {
                                onChange(country.code);
                                setIsOpen(false);
                            }}
                        >
                            <span className="flag">{country.flag}</span>
                            <span className="country-code">{country.code} ({country.name})</span>
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default CountrySelect;