import React, { useContext, useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { WebSocketContext } from './WebSocketProvider';
import LoadingSpinner from './LoadingSpinner';
import { Buffer } from 'buffer';
import './MessageList.css';
import CountrySelect from './CountrySelect';
// import { clear } from 'console';

const MessageList = forwardRef(({ sessionId, currentThreadId, userId, contacts, sendInvite, threads, isLoadingThread }, ref) => {
    const { messages, setMessages, imageFetch } = useContext(WebSocketContext);
    const messageListRef = useRef();
    const [showInviteModal, setShowInviteModal] = useState(false);
    const [inviteePhone, setInviteePhone] = useState('');
    const [inviteeCountryCode, setInviteeCountryCode] = useState('+1');
    const [fullscreenImage, setFullscreenImage] = useState(null);
    const [fullscreenImageId, setFullscreenImageId] = useState(null);
    const [securityTooltip, setSecurityTooltip] = useState({ show: false, x: 0, y: 0 });

    const generalThreadId = 'jD6oQl3BMSIZhXkhJnUHEfi6QzPMt0Vzb6dGjOi04rcIBY2Pv0NVwLQB3TbEtwXha0HlYDacBpRtriqnuAN4tNUCk';

    // Add global event listener to prevent context menu on images
    useEffect(() => {
        const preventImageContextMenu = (e) => {
            // If the right-clicked element is an image or has an image parent
            if (e.target.tagName === 'IMG' || e.target.querySelector('img') || e.target.closest('.image-modal')) {
                e.preventDefault();

                // Show tooltip near the cursor, but with vertical offset to ensure visibility
                setSecurityTooltip({
                    show: true,
                    x: e.clientX,
                    y: e.clientY - 20 // Add a 20px offset to position it above cursor
                });

                // Hide tooltip after 2 seconds
                setTimeout(() => {
                    setSecurityTooltip({ show: false, x: 0, y: 0 });
                }, 2000);

                return false;
            }
        };

        // Add the event listener to the window
        window.addEventListener('contextmenu', preventImageContextMenu);

        // Clean up when component unmounts
        return () => {
            window.removeEventListener('contextmenu', preventImageContextMenu);
        };
    }, []);

    const clearMessages = () => {
        setMessages([]);
    };

    const closeImage = () => {
        setMessages((prev) => prev.map((msg) => {
            if (msg.imageId === fullscreenImageId) {
                return {
                    ...msg,
                    image: null,
                    placeholder: true,
                    holding: false
                };
            } else {
                return msg;
            }
        }));
        setFullscreenImage(null);
        setFullscreenImageId(null);
    }

    useImperativeHandle(ref, () => ({
        clearMessages
    }));

    // useEffect(() => {
    //     if (!threads || threads.length === 0) {
    //         setIsLoading(false);
    //     }
    // }, [threads]);



    // Automatically scroll to the latest message
    useEffect(() => {
        const messageList = messageListRef.current;
        if (messageList) {
            const isAtBottom =
                messageList.scrollHeight - messageList.scrollTop === messageList.clientHeight;
            if (isAtBottom) {
                messageList.scrollTop = messageList.scrollHeight;
            }
        }
    }, [messages]);

    const validatePhoneNumber = (number) => {
        // At least 3 digits after country code and no whitespace
        return number.length >= 3 && !/\s/.test(number);
    };

    const getFullInviteePhoneNumber = () => {
        // Step 1: Remove all non-digits from the phone number
        const cleanPhone = inviteePhone.replace(/\D/g, '');

        // Step 2: Ensure the country code starts with a '+'
        const cleanCountryCode = inviteeCountryCode.startsWith('+')
            ? inviteeCountryCode
            : `+${inviteeCountryCode}`;

        // Step 3: Format the phone number based on country code
        let formattedPhone = cleanPhone;

        // For US numbers (country code '+1'), format as XXX XXX XXXX
        if (cleanCountryCode === '+1' && cleanPhone.length >= 10) {
            const areaCode = cleanPhone.substring(0, 3); // First 3 digits
            const prefix = cleanPhone.substring(3, 6); // Next 3 digits
            const lineNumber = cleanPhone.substring(6, 10); // Last 4 digits
            formattedPhone = `${areaCode}${prefix}${lineNumber}`;
        }

        // Step 4: Combine country code and formatted phone number with a space
        return `${cleanCountryCode} ${formattedPhone}`.trim();
    };

    const handleInviteUser = () => {
        const fullInviteePhone = getFullInviteePhoneNumber();

        if (!validatePhoneNumber(inviteePhone)) {
            console.error('Invalid phone number');
            return;
        }
        sendInvite(fullInviteePhone, currentThreadId);
        setInviteePhone('');
        setInviteeCountryCode('+1');
        setShowInviteModal(false);
    };

    const handleImageHold = async (imageId) => {
        console.log('Fetching image...');
        setMessages((prev) => prev.map((msg) => {
            if (msg.imageId === imageId) {
                return { ...msg, holding: true };
            }
            return msg;
        }));
        // imageFetch(imageId);

        try {
            const imageBuffer = await imageFetch(imageId);
            const imageBase64 = Buffer.from(imageBuffer).toString('base64');
            setFullscreenImage(`data:image/jpeg;base64,${imageBase64}`);
            setFullscreenImageId(imageId);
            setMessages((prev) => prev.map((msg) => {
                if (msg.imageId === imageId) {
                    return { ...msg, placeholder: false };
                }
                return msg;
            }));

        } catch (error) {
            console.error('Error fetching image:', error);
        }
    };

    const handleImageRelease = (imageId) => {
        console.log('Removing image from memory...');
        setFullscreenImage(null);
    };

    // Function to convert URLs in text to clickable links
    const renderTextWithLinks = (text) => {
        // Regular expression to match URLs
        const urlRegex = /(https?:\/\/[^\s]+)/g;

        // Split the text by URLs and map over the parts
        const parts = text.split(urlRegex);
        return parts.map((part, index) => {
            if (part.match(urlRegex)) {
                return (
                    <a
                        key={index}
                        href={part}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: 'inherit', textDecoration: 'underline' }}
                    >
                        {part}
                    </a>
                );
            }
            return part;
        });
    };

    if (isLoadingThread) {
        return (
            <div className="message-list-loading">
                <LoadingSpinner />
            </div>
        )
    } else {

        return (
            <div className="message-list" ref={messageListRef}>
                {/* Security tooltip */}
                {securityTooltip.show && (
                    <div
                        className="security-tooltip"
                        style={{
                            position: 'fixed',
                            top: `${securityTooltip.y}px`,
                            left: `${securityTooltip.x}px`,
                            transform: 'translate(-50%, -100%)',
                            background: 'rgba(0, 0, 0, 0.85)',
                            color: 'white',
                            padding: '10px 15px',
                            borderRadius: '6px',
                            zIndex: 10000,
                            fontSize: '15px',
                            fontWeight: 'bold',
                            pointerEvents: 'none',
                            boxShadow: '0 0 10px rgba(255, 255, 255, 0.4), 0 0 5px rgba(0, 0, 0, 0.5)',
                            border: '1px solid rgba(255, 255, 255, 0.2)'
                        }}
                    >
                        Image saving is disabled for security
                    </div>
                )}
                {currentThreadId !== generalThreadId &&
                    <FontAwesomeIcon
                        icon={faUserPlus}
                        className="add-user-icon"
                        title="Add User"
                        onClick={() => setShowInviteModal((prev) => !prev)}
                    />
                }
                {messages
                    .filter((msg) => msg.threadId === currentThreadId) // Only show messages in the current thread
                    .slice() // Create a shallow copy to avoid mutating the original array
                    .reverse() // Reverse the order to show the latest message at the bottom
                    .map((msg, index, arr) => {
                        // Extract the date from datetime if available
                        const currentDate = msg.datetime ? new Date(msg.datetime).toDateString() : null;
                        const previousDate = index < arr.length - 1 ? new Date(arr[index + 1].datetime).toDateString() : null;

                        const shouldShowDataSeparator = currentDate !== previousDate;

                        const formattedDate = currentDate ? new Date(msg.datetime).toLocaleDateString('en-US', { weekday: 'short', month: 'short', day: 'numeric' }) : null;

                        // Extract time from datetime if available
                        const formattedTime = msg.datetime ? new Date(msg.datetime).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' }) : null;
                        return (
                            <React.Fragment key={index}>
                                <div
                                    className={`message-container ${msg.userId === userId ? 'sent' : 'received'}`}
                                >
                                    <div className="message-header">
                                        {msg.userId !== userId && (
                                            <div className="sender-name">{msg.sender}</div>
                                        )}
                                        {formattedTime && (
                                            <span className={`message-time ${msg.userId === userId ? 'sent-time' : 'received-time'}`}>
                                                {formattedTime}
                                            </span>
                                        )}
                                    </div>
                                    {msg.text && (
                                        <div className={`message ${msg.userId === userId ? 'sent' : 'received'}`}>
                                            {renderTextWithLinks(msg.text)}
                                        </div>
                                    )}
                                    {msg.isImage && (
                                        <div
                                            className={`message image-message ${msg.userId === userId ? 'sent' : 'received'}`}
                                            onMouseDown={() => handleImageHold(msg.imageId)}
                                            onMouseUp={() => handleImageRelease(msg.imageId)}
                                            onTouchStart={() => handleImageHold(msg.imageId)}
                                            onTouchEnd={() => handleImageRelease(msg.imageId)}
                                            onContextMenu={(e) => e.preventDefault()}
                                            style={{ position: 'relative' }}
                                        >
                                            {msg.placeholder ? (
                                                <>
                                                    <img
                                                        src="/placeholder-image.jpg"
                                                        alt="Placeholder"
                                                        className="message-image"
                                                        onContextMenu={(e) => e.preventDefault()}
                                                        draggable="false"
                                                    />
                                                    {msg.holding && (
                                                        <div className="spinner-overlay">
                                                            <div className="spinner"></div>
                                                        </div>
                                                    )}
                                                </>
                                            ) : (
                                                // <img src={`data:image/jpeg;base64,${msg.image}`} alt="Fetched" className="message-image" />
                                                <img
                                                    src="/placeholder-image.jpg"
                                                    alt="Placeholder"
                                                    className="message-image"
                                                    onContextMenu={(e) => e.preventDefault()}
                                                    draggable="false"
                                                />
                                            )}
                                        </div>
                                    )}
                                </div>
                                {shouldShowDataSeparator && formattedDate && (
                                    <div className="date-separator">
                                        <span>{formattedDate}</span>
                                    </div>
                                )}
                            </React.Fragment>
                        )
                    })}

                {fullscreenImage && (
                    <div
                        className="image-modal"
                        onClick={closeImage}
                        onContextMenu={(e) => e.preventDefault()}
                    >
                        <img
                            src={fullscreenImage}
                            alt="Full-screen preview"
                            className="fullscreen-image"
                            onContextMenu={(e) => e.preventDefault()}
                            draggable="false"
                        />
                    </div>
                )}
                {/* Invite Modal */}
                {showInviteModal && (
                    <div className="modal-backdrop" onClick={() => setShowInviteModal(false)}>
                        <div className="modal" onClick={(e) => e.stopPropagation()}>
                            <h3>Invite to Tribe</h3>
                            <div className="phone-input-container">
                                <div className="country-select-container">
                                    <CountrySelect
                                        value={inviteeCountryCode}
                                        onChange={(value) => setInviteeCountryCode(value)}
                                    />
                                </div>
                                <input
                                    type="tel"
                                    value={inviteePhone}
                                    onChange={(e) => setInviteePhone(e.target.value.replace(/\D/g, ''))}
                                    placeholder="Invitee's phone number"
                                    className="phone-input"
                                />
                            </div>
                            {/* <div className="phone-input-container-modal">
                                <select
                                    value={inviteeCountryCode}
                                    onChange={(e) => setInviteeCountryCode(e.target.value)}
                                    className="country-select-modal"
                                >
                                    <option value="+1">🇺🇸 +1 (US)</option>
                                    <option value="+44">🇬🇧 +44 (UK)</option>
                                    <option value="+33">🇫🇷 +33 (FR)</option>
                                    <option value="+49">🇩🇪 +49 (DE)</option>
                                    <option value="+81">🇯🇵 +81 (JP)</option>
                                    
                                </select>
                                <input
                                    type="tel"
                                    value={inviteePhone}
                                    onChange={(e) => setInviteePhone(e.target.value.replace(/\D/g, ''))}
                                    placeholder="Invitee's Phone Number"
                                    className="phone-input-modal"
                                    onKeyDown={(e) => e.key === 'Enter' && handleInviteUser()}
                                />
                            </div> */}
                            {!validatePhoneNumber(inviteePhone) && inviteePhone && (
                                <p className="error-message">Entry not valid</p>
                            )}
                            <div className="modal-actions">
                                <button onClick={handleInviteUser} disabled={!validatePhoneNumber(inviteePhone)}>
                                    Send
                                </button>
                                <button onClick={() => setShowInviteModal(false)}>Cancel</button>
                            </div>
                        </div>
                    </div>
                )}

            </div>
        );
    }
});

export default MessageList;

